<template>
  <div class="public_box_mask_dialog">
    <el-dialog :visible.sync="DialogShow" :title="DialogTitle" :width="DialogWidth" :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape" :lock-scroll="true" :show-close="DialogCloseBtn" :before-close="beforeCloseEvent">

      <!-- 外部插入主体内容 -->
      <slot name="contentMinBox"></slot>

      <!-- 外部插入操作按钮 -->
      <template v-if="$slots.footerCententBox" slot="footer">
        <slot name="footerCententBox"></slot>
      </template>

    </el-dialog>
  </div>
</template>

<script>
// 自定义弹框
export default {
  name: "PublicBoxMaskDialog",
  data() {
    return {
    };
  },
  props: {
    // 模态框宽度
    DialogWidth: {
      type: String,
      default: "960px",
    },
    // 开启模态框
    DialogShow: {
      type: Boolean,
      default: false,
      required: true, // 是否必填
    },
    // 标题
    DialogTitle: {
      type: String,
      default: "提示",
    },
    // 是否可以通过点击 modal 关闭 Dialog
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    // 是否可以通过按下 ESC 关闭 Dialog
    closeOnPressEscape: {
      type: Boolean,
      default: false,
    },
    // 是否显示关闭按钮
    DialogCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},

  methods: {
    beforeCloseEvent() {
      this.$emit("DialogCloseEvente");
    },
  },
  beforeDestroy() { },

  watch: {},
};
</script>

<style lang="scss" scoped>
.public_box_mask_dialog {
  /deep/.el-dialog {
    margin: 0 auto;
    padding: 0;
    margin-top: 10px !important;
    height: calc(100vh - 30px);
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  /deep/.el-dialog__header {
    width: 100%;
    flex: 0 0 52px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
    position: relative;
    background-color: #3489fe;
    .el-dialog__title {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
    .el-dialog__headerbtn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: 0;
      font-size: 26px;
      height: 100%;
      &:hover {
        .el-dialog__close {
          transform: rotate(90deg);
        }
      }
    }
    .el-dialog__close {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(0);
      transition: all 0.18s;
      color: #fff;
      &:before {
        content: "\e79d" !important;
      }
    }
  }
  /deep/.el-dialog__body {
    width: 100%;
    flex: 1 !important;
    margin: 0;
    overflow-y: auto;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  /deep/.el-dialog__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
    flex: 0 0 60px;
    width: 100%;
    border-top: 1px solid #ebeef5;
  }
}
</style>